import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setShowNavbar(currentScrollY < lastScrollY || currentScrollY <= 0);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const navbarVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <motion.nav
      className="fixed top-5 z-50 mx-auto transition-transform duration-300"
      variants={navbarVariants}
      initial="hidden"
      animate={showNavbar ? "visible" : "hidden"}
      transition={{ duration: 0.3 }}
    >
      <div className="min-w-fit rounded-full mx-auto bg-white shadow dark:bg-gray-800">
        <div className="container flex items-center justify-between sm:justify-center p-5 gap-x-2 mx-auto text-gray-600 capitalize dark:text-gray-300">
          <Link
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            className="text-gray-800 dark:text-gray-200 border-b-2 border-blue-500 mx-1.5 sm:mx-6 transition-all duration-200 transform hover:scale-105 hover:text-gray-600 dark:hover:text-gray-400"
          >
            inicio
          </Link>
          <Link
            to="info"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            className="border-b-2 border-transparent hover:border-blue-500 hover:text-gray-800 dark:hover:text-gray-200 mx-1.5 sm:mx-6 transition-all duration-200 transform hover:scale-105"
          >
            evento
          </Link>
          <Link
            to="confirmation"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-200}
            className="border-b-2 border-transparent hover:border-blue-500 hover:text-gray-800 dark:hover:text-gray-200 mx-1.5 sm:mx-6 transition-all duration-200 transform hover:scale-105"
          >
            confirmar
          </Link>
          <Link
            to="location"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            className="border-b-2 border-transparent hover:border-blue-500 hover:text-gray-800 dark:hover:text-gray-200 mx-1.5 sm:mx-6 transition-all duration-200 transform hover:scale-105"
          >
            ubicación
          </Link>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navbar;
