import React from "react";

import Outlook from "../icons/Outlook";
import Google from "../icons/Google";

import appleCalendar from "../files/XVs de Natalia.ics";
import Apple from "../icons/Apple";

function FakeCalendar({ date }) {
  const month = date.toLocaleString("es", { month: "long" });
  const year = date.getFullYear();

  // Genera los días del mes
  const daysInMonth = new Date(year, date.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, date.getMonth(), 1).getDay();

  // Ajuste para comenzar la semana en domingo
  const offsetDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const emptyStartDays = Array.from({ length: offsetDays }).fill(null);

  return (
    <div
      className="max-w-xm mx-auto m-5 px-5 pb-5 pt-2 bg-white rounded-lg shadow-xl"
      id="calendar"
    >
      <div className="text-center text-lg font-bold text-white bg-secondary p-2 rounded">
        {month.charAt(0).toUpperCase() + month.slice(1)} {year}
      </div>
      <div className="grid grid-cols-7 gap-1 mt-2">
        {["L", "M", "M", "J", "V", "S", "D"].map((day) => (
          <div key={day + 30} className="text-xs font-bold text-gray-800 p-3">
            {day}
          </div>
        ))}
        {emptyStartDays.map((_, index) => (
          <div
            key={`empty-${index}`}
            className="text-sm text-center text-gray-800 py-1 border border-gray-200 rounded"
          >
            {/* Espacios vacíos */}
          </div>
        ))}
        {days.map((day) => (
          <div
            key={day}
            className={`text-sm text-center text-gray-800 py-1 border border-gray-200 rounded ${
              day === 8 ? "bg-blue-200 text-blue-900" : ""
            }`}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
}

function Calendar() {
  // Datos del evento de ejemplo
  const event = {
    title: "Xvs de Natalia",
    description: "Fiesta de 15 años de Natalia",
    location: "Salón de eventos",
    start: "20240608T200000", // Formato: AAAAMMDDTHHMMSS
    end: "20240609T010000", // Formato: AAAAMMDDTHHMMSS
  };

  // Construir URLs para diferentes servicios de calendario
  const googleUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    event.title
  )}&details=${encodeURIComponent(
    event.description
  )}&location=${encodeURIComponent(event.location)}&dates=${event.start}/${
    event.end
  }`;

  // Microsoft Outlook Online
  const outlookUrl = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
    event.title
  )}&body=${encodeURIComponent(event.description)}&startdt=${
    event.start
  }&enddt=${event.end}&location=${encodeURIComponent(event.location)}`;

  const startDate = new Date(2024, 5, 6);

  return (
    <div className="max-w-4xl mx-auto p-5">
      <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-800">
        Calendario del Evento
      </h2>

      <h3 className="text-xl sm:text-2xl font-semibold text-center text-gray-700 mt-4">
        {event.title}
      </h3>

      <p className="mt-2 text-lg text-center text-gray-600">
        8 de junio de 2024
      </p>
      <p className="mt-2 text-lg text-center text-gray-600">
        <span className="font-bold">Misa:</span> 18:00 pm
      </p>
      <p className="mt-2 text-lg text-center text-gray-600">
        <span className="font-bold">Evento:</span> 20:00 pm a 01:00 am
      </p>

      <div className="flex justify-center">
        <FakeCalendar date={startDate} />
      </div>

      {/* BUTTONS */}

      <p className="mt-4 text-center text-gray-600 w-11/12 sm:w-2/3 mx-auto mb-4">
        También puedes agregar el evento a tu calendario favorito con un solo
        click:
      </p>

      <div className="flex justify-center items-center mt-4 sm:flex-row flex-col gap-5 sm:gap-8">
        <a
          href={googleUrl}
          class="relative px-5 py-2 font-medium text-white group"
        >
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-slate-500 group-hover:bg-slate-700 group-hover:skew-x-12"></span>
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-slate-700 group-hover:bg-slate-500 group-hover:-skew-x-12"></span>
          <span class="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-slate-600 -rotate-12"></span>
          <span class="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-slate-400 -rotate-12"></span>
          <span class="relative flex flex-row items-center gap-2">
            {" "}
            <Google />
            Outlook
          </span>{" "}
        </a>

        <a
          href={outlookUrl}
          class="relative px-5 py-2 font-medium text-white group"
        >
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-slate-500 group-hover:bg-slate-700 group-hover:skew-x-12"></span>
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-slate-700 group-hover:bg-slate-500 group-hover:-skew-x-12"></span>
          <span class="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-slate-600 -rotate-12"></span>
          <span class="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-slate-400 -rotate-12"></span>
          <span class="relative flex flex-row items-center gap-2">
            {" "}
            <Outlook />
            Outlook
          </span>{" "}
        </a>

        <button
          class="relative px-5 py-2 font-medium text-white group"
          onClick={() => {
            window.open(appleCalendar, "_blank");
          }}
        >
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-slate-500 group-hover:bg-slate-700 group-hover:skew-x-12"></span>
          <span class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-slate-700 group-hover:bg-slate-500 group-hover:-skew-x-12"></span>
          <span class="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-slate-600 -rotate-12"></span>
          <span class="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-slate-400 -rotate-12"></span>
          <span class="relative flex flex-row items-center gap-2">
            {" "}
            <Apple />
            Apple
          </span>{" "}
        </button>
      </div>
    </div>
  );
}

export default Calendar;
