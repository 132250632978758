import { useState, useEffect } from "react";

export default function useScrollProgress() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const windowHeight = scrollHeight - clientHeight;
      const scrollProgress = (scrollTop / windowHeight) * 100;

      setProgress(scrollProgress);
    };

    window.addEventListener("scroll", updateProgress);
    updateProgress(); // Inicializa el valor en caso de que la página no comience en la parte superior.

    return () => window.removeEventListener("scroll", updateProgress);
  }, []);

  return progress;
}
