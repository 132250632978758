import React from "react";

function Footer() {
  return (
    <footer
      className="text-center p-4 mt-8 bg-blue-200 text-blue-900 w-full"
      id="footer"
    >
      <div className="container mx-auto">
        <div className="flex flex-col sm:flex-row items-center justify-around gap-4">
          <div className="">
            <p className="text-md sm:text-lg font-semibold">
              ¡Esperamos contar con tu presencia!
            </p>
            {/* <p className="text-sm">
              Por favor, confirma tu asistencia antes del 16 de mayo.
            </p> */}
          </div>
        </div>
        <div className="mt-4">
          <p className="text-xs flex flex-col sm:flex-row items-center justify-center gap-1">
            <div className="flex items-center gap-1">
              Diseñado con
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 sm:w-6 sm:h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                />
              </svg>
              por{" "}
              <a
                rel="noreferrer"
                href="https://diegopartidar.com"
                target="_blank"
                className="font-semibold underline"
              >
                Diego Partida
              </a>
            </div>
            <div>
              para el gran día de <span className="font-semibold">Natalia</span>
            </div>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
