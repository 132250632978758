import React from "react";

const DigitalInvitation = () => {
  const eventAddress = "Terraza Jardines de Bambú, Gdl, Jalisco";

  const eventTime = "Sábado, 8 de Junio de 2024 a las 8:00 PM";

  const endTime = "*El evento terminará a la 1:00 AM";

  const churchAddress = "Parroquia Santa María de Guadalupe, Gdl, Jalisco";

  const churchTime = "Sábado, 8 de Junio de 2024 a las 6:00 PM";

  return (
    <div
      className="flex flex-col items-center justify-center p-4 space-y-6 gap-y-4"
      id="location"
    >
      <div className="text-center">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-2 flex flex-row items-center justify-center gap-2">
          Ubicación de la iglesia{" "}
          <img
            width="32"
            height="32"
            src="https://img.icons8.com/ios/100/church.png"
            alt="church"
          />
        </h1>
        <p className="text-md font-medium">{churchAddress}</p>
        <p className="text-md text-gray-700 mb-2">{churchTime}</p>
        <iframe
          title="church-location"
          className="w-full md:w-96 h-64 rounded-lg shadow-lg mb-2 mx-auto mt-6"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238803.96902487474!2d-103.61653655996246!3d20.738143907236182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428aeeb0347d055%3A0xaad97fc224a63223!2sParroquia%20Santa%20Mar%C3%ADa%20de%20Guadalupe!5e0!3m2!1ses-419!2smx!4v1710211378657!5m2!1ses-419!2smx"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>

      <div className="text-center">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-2 flex flex-row items-center justify-center gap-2">
          Ubicación del Evento{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
        </h1>
        <p className="text-md font-medium">{eventAddress}</p>
        <p className="text-md text-gray-700">{eventTime}</p>
        <p className="text-xs font-semibold text-gray-700">{endTime}</p>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center space-y-6 sm:space-y-0 sm:space-x-6">
        <div className="text-center w-full sm:w-1/2">
          <iframe
            title="event-location"
            className="w-full md:w-96 h-64 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238803.72127817466!2d-103.61653591681386!3d20.73830089758513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac6c500c198d%3A0x6182ea7e8413bcae!2sTerraza%20Jardines%20de%20Bamb%C3%BA!5e0!3m2!1ses-419!2smx!4v1710210734572!5m2!1ses-419!2smx"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>

        <div className="text-center">
          <img
            src="./assets/images/terraza.jpeg"
            alt="Event"
            className="w-full md:w-96 h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalInvitation;
