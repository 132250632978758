import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

function Home({ eventDate }) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  // Estado para el countdown
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const unitNamesInSpanish = {
    days: "días",
    hours: "horas",
    minutes: "minutos",
    seconds: "segundos",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = eventDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [eventDate]);

  return (
    // <div className="relative h-screen flex sm:items-start items-center justify-center sm:justify-start sm:px-10 sm:pt-10">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
      className="relative h-screen w-screen flex flex-col items-center justify-center sm:px-10 sm:pt-10"
    >
      {/* Background video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        onLoadedData={() => setVideoLoaded(true)}
        className={`absolute inset-0 w-full h-full object-cover -z-10 ${
          videoLoaded ? "opacity-100" : "opacity-0"
        }`}
      >
        <source src="assets/videos/Butterflies.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* <img
        src="assets/images/hero.png"
        className="absolute inset-0 w-full h-full -z-10 opacity-100"
        alt="Natalia Gutierrez"
      /> */}

      {/* Layer */}
      <div className="absolute inset-0 bg-secondary opacity-95 -z-1"></div>

      <div className="relative z-20 text-white text-center bg-opacity-85 sm:p-10 rounded-lg">
        <h1 className="text-6xl sm:text-8xl font-bold mb-8">
          <em>Natinatt</em>
        </h1>
        <p className="text-2xl sm:text-3xl lg:text-5xl sm:mt-0 mt-5">
          <em>Mis XV Años</em>
        </p>
      </div>

      <div className="relative z-20 text-white text-center bg-opacity-85 sm:p-10 rounded-lg sm:mt-10 mt-20 flex justify-center items-center gap-5 sm:gap-8">
        {/* Creando un "papel" para cada unidad del countdown */}
        {Object.entries(countdown).map(([unit, value]) => (
          <div className="flex flex-col items-center" key={unit}>
            <div className="p-4 rounded-lg shadow-lg transform transition duration-500 bg-slate-800">
              <span className="text-4xl md:text-5xl lg:text-6xl font-mono">
                <em>0</em>
              </span>
            </div>
            <span className="text-base md:text-lg lg:text-xl mt-2 capitalize">
              {unitNamesInSpanish[unit]}
            </span>
          </div>
        ))}
      </div>

      {/* Date */}
      <div className="relative z-20 text-white text-center bg-opacity-85 sm:mt-0 mt-10">
        <Link
          to="confirmation"
          smooth={true}
          duration={500}
          spy={true}
          offset={-200}
        >
          <p className="text-2xl lg:text-3xl">
            <em className="text-gray-100 underline">
              Sábado 8 de Junio del 2024
            </em>
          </p>
        </Link>
      </div>

      {/* Arrow down */}
      <div className="absolute bottom-10 animate-bounce cursor-pointer">
        <Link to="info" smooth={true} duration={500} spy={true} offset={-80}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
        </Link>
      </div>
    </motion.div>
  );
}

export default Home;
