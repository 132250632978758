import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Location from "./components/Location";
import AnimatedCursor from "react-animated-cursor";
import Calendar from "./components/Calendar";
import useScrollProgress from "./components/useScrollProgress";
import Footer from "./components/Footer";
import Info from "./components/Info";
import Donation from "./components/Donation";
import Dresscode from "./components/Dresscode";
import Confirmation from "./components/Confirmation";
import { Toaster } from "react-hot-toast";
import ConfirmationButton from "./components/ConfirmationButton";

function App() {
  const eventDate = new Date("2024-06-08T19:00:00"); // Año-Mes-Día
  const scrollProgress = useScrollProgress();
  return (
    <div className="App flex items-center flex-col justify-center">
      <div className="hidden sm:flex">
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={0.7}
          outerScale={1.5}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: "rgba(182, 132, 183, 0.5)",
          }}
          outerStyle={{
            border: "3px solid rgba(182, 132, 183, 0.5)",
          }}
        />
      </div>
      <Navbar />
      <div>
        <Toaster />
      </div>
      <Home eventDate={eventDate} />
      <div className="bg-gradient-to-b from-quaternary to-white h-8"></div>
      <Info />
      <hr className="border-2 w-2/3 mx-auto my-8" />
      <Dresscode />
      <hr className="border-2 w-2/3 mx-auto my-8" />
      <Calendar />
      <hr className="border-2 w-2/3 mx-auto my-8" />
      <Donation />
      {/* <hr className="border-2 w-2/3 mx-auto my-8" />
      <Confirmation /> */}
      <hr className="border-2 w-2/3 mx-auto my-8" />
      <Location />
      {/* <hr className="border-2 w-2/3 mx-auto my-8" /> */}
      {/* <ConfirmationButton /> */}

      <Footer />
      {scrollProgress > 1 && (
        <div
          className="fixed top-0 left-0 right-0 z-50 h-1 bg-blue-300"
          style={{ width: `${scrollProgress}%` }}
        />
      )}
    </div>
  );
}

export default App;
