const Donation = () => {
  return (
    <div
      className="bg-white rounded-xl shadow-lg p-8 mx-auto w-5/6 max-w-md"
      id="donation"
    >
      <div className="flex items-center mb-4">
        <h1 className="text-2xl font-bold">Sugerencia de regalo</h1>
      </div>

      <p className="text-md text-gray-700">
        ¡Tu presencia es el mejor regalo que podría recibir!
      </p>
      <p className="text-md text-gray-700">
        Si deseas hacerme un obsequio adicional a continuación te dejo una
        sugerencia:
      </p>
      <div className="flex flex-col items-center justify-start mt-6 gap-x-4">
        <p className="text-xl text-gray-700 font-semibold">
          "Lluvia de sobres"
        </p>{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 mt-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
          />
        </svg>
      </div>
    </div>
  );
};

export default Donation;
