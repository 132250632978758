const Dresscode = () => {
  return (
    <div className="p-8 text-center">
      <div className="flex items-center justify-center mb-4">
        <h1 className="text-2xl sm:text-3xl font-bold">Código de Vestimenta</h1>
      </div>
      <img
        src="assets/images/clothes.png"
        alt="Dresscode"
        className="w-28 h-28 sm:w-40 sm:h-40 mx-auto my-8"
      />
      <span className="text-3xl font-bold">Formal</span>.
      <p className="text-lg text-gray-700 w-11/12 sm:w-2/3 mx-auto mt-4">
        <span className="bg-[#C4E4FF]/50 p-1 rounded-md font-bold text-[#378CE7]">
          Azul
        </span>{" "}
        reservado para la quinceañera
      </p>
    </div>
  );
};

export default Dresscode;
