import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";

import "./Info.css";

function Info() {
  return (
    <div
      className="flex flex-wrap justify-center items-center flex-col my-4"
      id="info"
    >
      <div className="flex-1 p-4 text-center">
        <div className="font-bold mb-4 flex flex-col items-center justify-center">
          <p className="text-[#378CE7] text-5xl">Natalia</p>{" "}
          <p className="mt-3 text-3xl">Gutiérrez Partida</p>
        </div>
        <p className="text-lg text-gray-600 sm:w-2/3 mx-auto">
          Me siento muy emocionada de compartir este día tan especial con
          ustedes.
        </p>

        <div className="mt-4">
          <p className="text-xl font-semibold">Mis papás:</p>
          <p className="text-lg">
            Luis Enrique Gutiérrez & <br />
            Teresa Partida
          </p>
        </div>

        <div className="mt-4">
          <p className="text-xl font-semibold">Mis padrinos:</p>
          <p className="text-lg">
            Luis Manuel Ayala & <br />
            Vicky Gutiérrez
          </p>
        </div>
      </div>

      <div className="w-full sm:w-3/4 p-4 relative">
        <div className="hidden sm:block">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            initialSlide={1}
            className="mySwiper"
          >
            {" "}
            <SwiperSlide>
              <img
                src="assets/images/nati5.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati1.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati3.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati2.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="sm:hidden">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            initialSlide={2}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src="assets/images/nati1.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati3.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati5.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati4.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="assets/images/nati2.jpeg"
                alt="Natalia Gutierrez"
                className="w-full h-72 object-cover rounded-lg mx-auto"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Info;
